// import HeatmapJS from "heatmap.js";、
import {
	BillboardGraphics,
	BoundingSphere,
	buildModuleUrl,
	Cartesian2,
	Cartesian3,
	Color as CesiumColor,
	ConstantProperty,
	Entity,
	Ion as CesiumIon,
	Math as CesiumMath,
	Rectangle,
	ScreenSpaceEventHandler,
	ScreenSpaceEventType,
	TileMapServiceImageryProvider,
	UrlTemplateImageryProvider,
	Viewer as CesiumViewer,
	WebMercatorTilingScheme,
	PolygonHierarchy,
	LabelStyle,
	DistanceDisplayCondition,
	NearFarScalar,
	PolygonGraphics,
	ColorMaterialProperty,
	LabelGraphics,
} from "cesium";
import * as turf from "turf";
import React, { Component, RefObject } from "react";
import { getOssUrl, transform } from "../../assets/js/methods";
import accomplish from "../../assets/svg/accomplish.svg";
import cs from "../../assets/svg/cs.svg";
import defectfff from "../../assets/svg/defectfff.svg";
import defectRed from "../../assets/svg/defectRed.svg";
import untreated from "../../assets/svg/untreated.svg";
import { PointStatus } from "../../typings/interface";
import CESIUM_CONFIG from "./config";
import { getSpecificEntities } from "./methods";
interface Props {
	clickCallback: (point: Entity) => any;
}

type Defect = {
	id: number;
	tiff_positions: Array<{
		longitude: number;
		latitude: number;
	}>;
	is_deleted: boolean;
	patrol_point_id: number;
	defect_type: {
		id: number;
		defect_type: number;
		defect_name: string;
		defect_color: string;
	};
};
buildModuleUrl.setBaseUrl("./cesium/") as any;

export default class CesiumMap extends Component<Props> {
	props: Props;
	viewer: CesiumViewer | null;
	eventHandler: ScreenSpaceEventHandler | null;
	public mapRef: RefObject<HTMLDivElement>;

	constructor(props: Props) {
		super(props);
		this.props = props;
		this.viewer = null;
		this.mapRef = React.createRef();
		this.eventHandler = null;
	}

	componentDidMount() {
		if (this.mapRef.current) {
			//初始化map

			CesiumIon.defaultAccessToken = CESIUM_CONFIG.ION;
			this.viewer = new CesiumViewer(
				this.mapRef.current,
				CESIUM_CONFIG.MAPOPTIONS,
			);
			this.eventHandler = new ScreenSpaceEventHandler(this.viewer.scene.canvas);
			this.viewer.scene.globe.enableLighting = true;
			this.viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
				ScreenSpaceEventType.LEFT_DOUBLE_CLICK,
			);
			// 点击事件注册
			this.eventHandler.setInputAction(
				this.handleMapClick,
				ScreenSpaceEventType.LEFT_CLICK,
			);
		}
	}
	setViewer = (destination: Cartesian3) => {
		this.viewer!.camera.setView({
			destination: destination,
			orientation: {
				pitch: CesiumMath.toRadians(-90),
				roll: -0.09,
				heading: 0,
			},
		});
	};

	addNetworkMapLayer = (provider: TileMapServiceImageryProvider) => {
		provider.errorEvent.addEventListener(
			(e) => e.error.statusCode !== 404 && console.log(e),
		);
		this.viewer!.imageryLayers.addImageryProvider(provider);
	};

	handleMapClick = (e: any) => {
		const clickPosition = e.position;
		const pickedObject = this.viewer!.scene.pick(clickPosition);
		if (pickedObject) {
			const payload = pickedObject.id as Entity;
			// console.log(payload);
			this.props.clickCallback(payload);
		}
	};

	loadTiff = (
		url: string,
		bound: [number, number],
		level: number,
		setCurrentPlantTiff: any,
	) => {
		// console.log("url", url);
		const orthoRect = new Rectangle(
			CesiumMath.toRadians(bound[0] - 0.5),
			CesiumMath.toRadians(bound[1] - 0.5),
			CesiumMath.toRadians(bound[0] + 0.5),
			CesiumMath.toRadians(bound[1] + 0.5),
		);

		// const ossUrl = getOssUrl(url);
		// console.log("ossUrl", ossUrl);
		const tms = new UrlTemplateImageryProvider({
			url: url,
			// url: ossUrl,
			tilingScheme: new WebMercatorTilingScheme(),
			credit: "Setqy",
			// fileExtension: "png",
			maximumLevel: level,
			minimumLevel: 13,
			rectangle: orthoRect,
		});
		console.log(tms);
		tms.errorEvent.addEventListener((e) => {
			e.error.statusCode !== 404 && console.log(e);
		});

		setTimeout(() => {
			try {
				// 超时得检查viewer是否存在, 可能会被销毁
				const tiff = this.viewer!.imageryLayers.addImageryProvider(tms);
				console.log(tiff);
				setCurrentPlantTiff(tiff);
			} catch (e) {
				console.warn("[地图图层] 组件已被销毁");
			}
		}, 1200);
		return;
	};

	flyLongitudeTo(mapNormalLongitude: number, mapNormalLatitude: number) {
		// let position = transform(mapNormalLongitude * 1, mapNormalLatitude * 1);
		let position = [Number(mapNormalLongitude), Number(mapNormalLatitude)];

		this.viewer!.camera.flyTo({
			destination: Cartesian3.fromDegrees(position[0], position[1], 3000),
			duration: 2,
		});
	}

	// 

	// 任务撒点列表绘制
	paintPoints = async (points: any[] = [], map: CesiumMap) => {
		console.log(points);
		const entities = getSpecificEntities(
			map.viewer!.entities?.values,
			undefined,
			["drone"],
		);
		map!.removeSpecificEntities(entities);
		await points.forEach((item) => {
			// let position = transform(item.longitude * 1, item.latitude * 1);
			let position = [Number(item.longitude), Number(item.latitude)];

			//! 航点图标显示
			const payload = {
				id: item.id,
				name: item.name,
				color: item.color,
				position: Cartesian3.fromDegrees(position[0], position[1], 2),
				outline: { color: "#000", width: 2 },
				description: JSON.stringify(item),
				status: item.status,
			};

			this.cesiumPoint(
				payload,
				item.ids,
				item.defect_status && +item.defect_status,
			);

			// 渲染缺陷
			if(item.defect_status === 1 || item.defect_status === 4 || item.defect_status === 5 || item.defect_status === 0) {
        for (let i = 0; i < item.defects.length; i++) {
          const defect = item.defects[i] as Defect;
          if (defect.is_deleted === false && defect.tiff_positions && defect.tiff_positions.length) {
            const transPositions = defect.tiff_positions.map((v) => [
              v.longitude,
              v.latitude,
            ]);
			const polygon = turf.polygon([[...transPositions, transPositions[0]]])
			const featureCollection = turf.featureCollection([polygon])
			const center = turf.center(featureCollection)
			const centerPosition = center.geometry.coordinates
			const params = {
				center: Cartesian3.fromDegrees(centerPosition[0], centerPosition[1]),
				polygonPositions: defect.tiff_positions.map((v) => Cartesian3.fromDegrees(v.longitude, v.latitude)),
				color: defect.defect_type.defect_color,
				text: `${defect.id}`.slice(0,7),
			}
			this.cesiumDefect(params);
          }
        }
      }
		});
		console.log("撒点完成::");
	};

	// draw defect
	cesiumDefect = ({center, polygonPositions, color, text}:{
		center: Cartesian3;
		polygonPositions: Cartesian3[];
		color: string;
		text: string;
	}) => {
		const rgb = color.split(',');
		this.viewer!.entities.add(new Entity({
			position: center,
			description: new ConstantProperty("defect"),
			polygon: new PolygonGraphics({
				hierarchy: new PolygonHierarchy(polygonPositions),
				material: new ColorMaterialProperty(CesiumColor.fromBytes(+rgb[0], +rgb[1], +rgb[2]).withAlpha(0.4)),
			  }),
			label: new LabelGraphics({
				text: new ConstantProperty(text),
				font: "16px sans-serif",
				fillColor: CesiumColor.WHITE,
				outlineColor: CesiumColor.BLACK,
				outlineWidth: 2,
				style: new ConstantProperty(LabelStyle.FILL_AND_OUTLINE),
				distanceDisplayCondition: new ConstantProperty(	new DistanceDisplayCondition(0, 150)),
				pixelOffsetScaleByDistance: new ConstantProperty(new NearFarScalar(0,0.3, 2e2, 1.0))
					})
		}));
	}

	//筛选类型后航点渲染
	// 任务撒点列表绘制
	filterPaintPoints = (points: any[] = [], map: CesiumMap) => {
		const entities = getSpecificEntities(
			map.viewer!.entities?.values,
			undefined,
			["drone"],
		);
		map!.removeSpecificEntities(entities);

		points.forEach((item) => {
			// let position = transform(+item.longitude, +item.latitude);
			let position = [Number(item.longitude), Number(item.latitude)];
			//! 航点图标显示
			const payload = {
				id: item.id,
				name: item.name,
				color: item.color,
				position: Cartesian3.fromDegrees(position[0], position[1], 2),
				outline: { color: "#000", width: 2 },
				description: JSON.stringify(item),
				status: item.status,
			};
			const defects = item.defects;
			let defectStatus = null;
			if (defects.length) {
				const isRedColor = defects.every((v: any) => v.defect_status.id === 1); //全部未处理
				const isGreenColor = defects.every(
					(v: any) => v.defect_status.id !== 1,
				); //全部已处理
				const isYellowColor = defects.find(
					(v: any) => v.defect_status.id === 1,
				); //部分未处理

				//! 不要打乱顺序 层级 全部未处理(红色)->  全部已处理(绿色)  -> 部分未处理 (黄色)
				//部分未处理
				if (isYellowColor) {
					defectStatus = PointStatus.PartUntreated;
				}

				//全部已处理
				if (isGreenColor) {
					defectStatus = PointStatus.allTreated;
				}
				//全部未处理 有缺陷
				if (isRedColor) {
					defectStatus = PointStatus.hasDefect;
				}
			} else {
				defectStatus = PointStatus.noDefect; //上传后没有缺陷
			}

			this.cesiumPoint(payload, item.ids, defectStatus && +defectStatus);
		});
		console.log("撒点完成::");
	};

	cesiumPointPolygon = (item: any) => {
		if (!item.region) return;
		this.viewer!.entities.values.filter(
			(e) => e.polygon && e.description.getValue() === "region",
		).forEach((l) => this.viewer!.entities.remove(l));

		let list: any = [];
		for (let i = 0; i < item.region?.flat().length - 1; i += 2) {
			list.push(
				// ...transform(item.region?.flat()[i], item.region?.flat()[i + 1]),
				...[item.region?.flat()[i], item.region?.flat()[i + 1]],
			);
		}

		this.viewer!.entities.add({
			position: BoundingSphere.fromPoints(list).center, // 坐标为矩形中间
			description: "region",
			polygon: {
				extrudedHeight: 0,
				perPositionHeight: true,
				hierarchy: Cartesian3.fromDegreesArray(list),
				outline: true,
				outlineWidth: 10,
				outlineColor: CesiumColor.fromCssColorString("rgba(0, 0, 0, 1)"),
				material: CesiumColor.fromCssColorString("rgba(255, 0, 0, 0.1)"),
			},
		} as any);
	};
	/**
	 * cesium绘制点
	 * @param {string} name
	 * @param {*} position
	 * @param {string} color
	 * @param {width:number,color:string} outline {width:0,color:''}
	 * @param {string} data
	 * @param {string} text
	 * @memberof CesiumMap
	 */
	cesiumPoint = (
		{
			id,
			name,
			position,
			billboard,
			color,
			outline,
			description,
			text = "",
			status,
		}: any,
		wpgId: any,
		defectStatus: number | null,
	) => {
		//0 上传没有缺陷  1 上传有缺陷 2 未上传没有缺陷 3 未上传有缺陷
		const entity: any = {
			name: "point",
			id: id,
			wpgId: wpgId,
			position: position,
			description: description,
		};

		if (defectStatus === 0) {
			//上传后没有缺陷
			let billboard = new BillboardGraphics({
				image: new ConstantProperty(defectfff),
				scale: new ConstantProperty(0.8),
			});
			entity.billboard = billboard;
		}

		if (defectStatus === 1) {
			//上传后有缺陷
			let billboard = new BillboardGraphics({
				image: new ConstantProperty(defectRed),
				scale: new ConstantProperty(0.8),
			});
			entity.billboard = billboard;
		}

		if (defectStatus === 4) {
			//缺陷未处理
			let billboard = new BillboardGraphics({
				image: new ConstantProperty(untreated),
				scale: new ConstantProperty(0.8),
			});
			entity.billboard = billboard;
		}
		if (defectStatus === 5) {
			//缺陷已处理
			let billboard = new BillboardGraphics({
				image: new ConstantProperty(accomplish),
				scale: new ConstantProperty(0.8),
			});
			entity.billboard = billboard;
		}
		return this.viewer!.entities.add(entity);
	};
	flyToCenter(center: [number, number]) {
		const viewRect = new Rectangle(
			CesiumMath.toRadians(center[0] - 0.01),
			CesiumMath.toRadians(center[1] - 0.01),
			CesiumMath.toRadians(center[0] + 0.01),
			CesiumMath.toRadians(center[1] + 0.01),
		);

		this.viewer!.camera.flyTo({
			destination: viewRect,
			duration: 2.5,
		});
	}

	zoomIn = () => {
		let cameraPos = this.viewer!.camera.position;
		// 获取当前坐标系标准
		let ellipsoid = this.viewer!.scene.globe.ellipsoid;

		// 根据坐标系标准，将笛卡尔坐标转换为地理坐标
		let cartographic = ellipsoid.cartesianToCartographic(cameraPos);

		// 获取镜头的高度
		let height = cartographic.height;
		if (height < 10) {
			return;
		}
		this.viewer!.camera.zoomIn(height / 3);
	};

	zoomOut = () => {
		let cameraPos = this.viewer!.camera.position;
		// 获取当前坐标系标准
		let ellipsoid = this.viewer!.scene.globe.ellipsoid;

		// 根据坐标系标准，将笛卡尔坐标转换为地理坐标
		let cartographic = ellipsoid.cartesianToCartographic(cameraPos);

		// 获取镜头的高度
		let height = cartographic.height;
		if (height <= 1090657211444) {
			//镜头缩小限制 数量限制
			this.viewer!.camera.zoomOut(height * 1.2);
		}
	};

	// 加载模型(无人机,机库等)
	cesiumModel = (
		name: string,
		userId: number,
		modelUrl: string,
		position: any,
		description: "drone",
	) => {
		const modelOptions: any = {
			name: "无人机",
			user_id: userId,
			position: position,
			description: description,
			billboard: new BillboardGraphics({
				image: new ConstantProperty(cs),
				scale: new ConstantProperty(1),
			}),
		};
		let entity = this.viewer!.entities.add(modelOptions);
		console.log("[Cesium] 生成模型实例", { entity });
		return entity;
	};

	flyToBoundCenter(center: [number, number]) {
		const viewRect = new Rectangle(
			CesiumMath.toRadians(center[0] - 0.01),
			CesiumMath.toRadians(center[1] - 0.01),
			CesiumMath.toRadians(center[0] + 0.01),
			CesiumMath.toRadians(center[1] + 0.01),
		);

		this.viewer!.camera.flyTo({
			destination: viewRect,
			duration: 2.5,
		});
	}

	/**
	 *
	 * cesium绘制航线点
	 * @memberof CesiumMap
	 */
	cesiumWayPoint = (
		{
			name,
			position,
			color,
			outline = null,
			text = "",
			data,
			description,
		}: any,
		wpgId: any,
	) => {
		const entity: any = {
			name: wpgId,
			wpgId: wpgId,
			position: position,
			description: description,
			point: {
				color: CesiumColor.fromCssColorString(color),
				pixelSize: 10,
			},
			label: {
				text: text,
				font: "16px Helvetica",
				fillColor: CesiumColor.fromCssColorString("#fff"),
				pixelOffset: new Cartesian2(70, 0),
			},
		};
		if (outline) {
			entity.point.outlineColor = CesiumColor.fromCssColorString(outline.color);
			entity.point.outlineWidth = outline.width;
		}
		return this.viewer!.entities.add(entity);
	};

	getViewer = () => {
		return this.viewer;
	};

	removeAllEntity = () => {
		console.log("清理所有实体::");
		if (!this.viewer!.entities) return;
		this.viewer!.entities.removeAll();
	};
	/**
	 * 清理地图实体(指定实例对象)
	 * @param {Entity[]} entities
	 */
	removeSpecificEntities(entities: Entity[]) {
		entities.forEach((entity) => this.viewer!.entities.remove(entity));
	}

	render() {
		return <div id="cesiumMap" ref={this.mapRef} style={{ height: "100%" }} />;
	}
}
